var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pb-base-modal",
    {
      attrs: {
        maxWidth: 500,
        expandable: false,
        withActions: false,
        withApply: false
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(_vm._s(_vm.$t("manageTagsLabel")))]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "pb-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    icon: "",
                    right: "",
                    label: _vm.$t("addStatusLabel")
                  },
                  on: { click: _vm.createTag }
                },
                [_c("v-icon", [_vm._v("fal fa-plus")])],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.localValue,
        callback: function($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-expansion-panels",
            {
              attrs: { flat: "", multiple: "", role: "tablist" },
              model: {
                value: _vm.panels,
                callback: function($$v) {
                  _vm.panels = $$v
                },
                expression: "panels"
              }
            },
            [
              _c(
                "v-expansion-panel",
                { attrs: { role: "tab" } },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(_vm._s(_vm.$t("sampleTagsLabel")))
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c("v-card-text", [
                                    _c(
                                      "span",
                                      { staticClass: "title-on-border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sampleTagsDisability"))
                                        )
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      _vm._l(_vm.SampleDisabilities, function(
                                        item,
                                        i
                                      ) {
                                        return _c("div", { key: i }, [
                                          _c("li", [
                                            _vm._v(_vm._s(item.tagText))
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addTags(
                                                    _vm.SampleDisabilities
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("addLabel"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c("v-card-text", [
                                    _c(
                                      "span",
                                      { staticClass: "title-on-border" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("sampleTagsMedical"))
                                        )
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      _vm._l(_vm.SampleTypes, function(
                                        item,
                                        i
                                      ) {
                                        return _c("div", { key: i }, [
                                          _c("li", [
                                            _vm._v(_vm._s(item.tagText))
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addTags(
                                                    _vm.SampleTypes
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("addLabel"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { outlined: "" } },
                                [
                                  _c("v-card-text", [
                                    _c(
                                      "span",
                                      { staticClass: "title-on-border" },
                                      [_vm._v(_vm._s(_vm.$t("otherLabel")))]
                                    ),
                                    _c(
                                      "ul",
                                      _vm._l(_vm.SampleOther, function(
                                        item,
                                        i
                                      ) {
                                        return _c("div", { key: i }, [
                                          _c("li", [
                                            _vm._v(_vm._s(item.tagText))
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "center",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addTags(
                                                    _vm.SampleOther
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("addLabel"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$currentUser.isTeacher && _vm.fixedTags.length > 0
                ? _c(
                    "v-expansion-panel",
                    { attrs: { role: "tab" } },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(_vm._s(_vm.$t("schoolTagsLabel")))
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-list",
                            { staticClass: "pt-0 pb-0" },
                            _vm._l(_vm.fixedTags, function(item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.studentTagId,
                                  attrs: { dense: "" }
                                },
                                [
                                  _c(
                                    "v-list-item-action",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: item.color } },
                                        [_vm._v("fa fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-content", [
                                    _c("span", [_vm._v(_vm._s(item.tagText))])
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-expansion-panel",
                { attrs: { role: "tab" } },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(
                      _vm._s(
                        _vm.$currentUser.isTeacher
                          ? _vm.$t("yourTagsLabel")
                          : _vm.$t("schoolTagsLabel")
                      )
                    )
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _vm.editableTags.length != 0
                        ? _c(
                            "v-list",
                            { staticClass: "pt-0 pb-0" },
                            _vm._l(_vm.editableTags, function(item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.studentTagId,
                                  attrs: { dense: "" }
                                },
                                [
                                  _c(
                                    "v-list-item-action",
                                    { staticClass: "mr-3" },
                                    [
                                      _c("pb-color-picker", {
                                        on: {
                                          change: function($event) {
                                            return _vm.doApplyTag(item)
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: item.color,
                                                            "aria-label": _vm.$t(
                                                              "pickColorLabel"
                                                            )
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("fas fa-circle")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: item.color,
                                          callback: function($$v) {
                                            _vm.$set(item, "color", $$v)
                                          },
                                          expression: "item.color"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      !item.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editTag(item)
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(item.tagText))]
                                          )
                                        : _c("v-text-field", {
                                            ref: "text" + item.studentTagId,
                                            refInFor: true,
                                            staticClass: "pt-0 mr-5",
                                            attrs: { "hide-details": "auto" },
                                            model: {
                                              value: item.tagText,
                                              callback: function($$v) {
                                                _vm.$set(item, "tagText", $$v)
                                              },
                                              expression: "item.tagText"
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  !item.edit
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            icon: "",
                                            "aria-label": _vm.$t(
                                              "editStatusLabel"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editTag(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "info" } },
                                            [_vm._v("fal fa-pencil")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !item.edit
                                    ? _c("pb-color-picker", {
                                        on: {
                                          change: function($event) {
                                            return _vm.doApplyTag(item)
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "mr-3 focus-icon-border",
                                                          attrs: {
                                                            tabindex: "0",
                                                            role: "button",
                                                            color: item.color,
                                                            "aria-label": _vm.$t(
                                                              "pickColorLabel"
                                                            )
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "fas fa-eye-dropper"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: item.color,
                                          callback: function($$v) {
                                            _vm.$set(item, "color", $$v)
                                          },
                                          expression: "item.color"
                                        }
                                      })
                                    : _vm._e(),
                                  !item.edit
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            "aria-label": _vm.$t(
                                              "deleteStatusLabel"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "info" } },
                                            [_vm._v("fal fa-trash-alt")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.edit
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            "aria-label": _vm.$t(
                                              "saveStatusLabel"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.doApplyTag(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "info" } },
                                            [_vm._v("fal fa-check")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.$t("noTagsLabel")))])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
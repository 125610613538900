














































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ld from 'lodash';
import Confirm from '../core/Confirm.vue';
import { namespace } from 'vuex-class';

const studentTags = namespace('studenttags');
const settings = namespace('settings');

@Component
export default class StudentTags extends Vue {
  @Prop({ type: Boolean })
  value!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('close');
  }

  @studentTags.Action
  loadTags!: (param?: any) => Promise<any>;

  @studentTags.Getter
  getTags!: Array<any>

  @studentTags.Action
  addTag!: (param?: any) => Promise<any>;

  @studentTags.Action
  updateTag!: (param?: any) => Promise<any>;

  @studentTags.Action
  deleteTag!: (param?: any) => Promise<any>;

  SampleTypes = [{ color: '#980000', tagText: 'Speech', desc: '' },
    { color: '#134F5C', tagText: 'Hearing', desc: '' },
    { color: '#351C75', tagText: 'Medical', desc: '' }]

  SampleDisabilities = [{ color: '#DD7E6B', tagText: 'ESE', desc: 'Exceptional Student Education' },
    { color: '#F9CB9C', tagText: 'ELL', desc: 'English Language Learner' },
    { color: '#FFE599', tagText: 'SLD', desc: 'Specific Learning Disability' },
    { color: '#B6D7A8', tagText: 'EBD', desc: 'Emotional Behavioral Disability' },
    { color: '#A4C2F4', tagText: 'IEP', desc: 'Individualized Education Program' },
    { color: '#B4A7D6', tagText: '504 Plan', desc: '' }]

  SampleOther = [{ color: '#FF0000', tagText: 'FRL', desc: 'Free and Reduced Lunch' },
    { color: '#FF9900', tagText: 'Gifted', desc: '' }];

  panels = [1, 2];
  editableTags: any = [];
  fixedTags: any = [];
  $refs!: {
    confirm: Confirm
  }

  created() {
    this.loadTags().then(() => {
      this.init();
    })
  }

  refresh() {
    this.loadTags().then(() => {
      this.init();
    })
  }

  @Watch('value')
  init() {
    if (this.value === false) {
      return;
    }

    this.panels = [1, 2];
    const allTags = this.getTags;
    this.editableTags = [];
    this.fixedTags = [];
    allTags.map((a: any) => {
      if (this.$currentUser.isAdmin) {
        if (a.schoolId || a.districtId) {
          a.edit = false;
          this.editableTags.push(a);
        }
      } else {
        if (a.schoolId || a.districtId) {
          this.fixedTags.push(a);
        } else {
          a.edit = false;
          this.editableTags.push(a);
        }
      }
    })
  }

  addTags(list: any[]) {
    Promise.all(
      list.map((a: any) => {
        return this.addTag(a);
      })
    ).then(() => {
      this.refresh();
    })
  }

  createTag() {
    this.addTag({ color: '#000000', tagText: 'New Tag', desc: '' }).then(() => {
      this.refresh();
    })
  }

  applyTag() {
    this.editableTags.map((a: any) => {
      a.edit = false;
    })
  }

  doApplyTag(param: any) {
    this.updateTag({ tagId: param.studentTagId, color: param.color, tagText: param.tagText, desc: param.desc }).then(() => {
      this.refresh();
    })
  }

  editTag(item: any) {
    const tags = ld.cloneDeep(this.editableTags);
    const toUpdate = tags.find((s: any) => s.studentTagId === item.studentTagId);
    tags.forEach((s: any) => { s.edit = false });
    toUpdate.edit = true;
    this.editableTags = tags;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        try {
          (this.$refs as any)[`text${item.studentTagId}`][0].$el.querySelector('input').focus()
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      });
    })
  }

  deleteItem(item: any) {
    return this.$refs.confirm.confirm({
      title: this.$t('tagsLabel'),
      text: this.$t('confirmDeleteGeneral', { type: 'tag' }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.applyTag()
        this.doDeleteItem(item).then(() => {
          this.$eventBus.$emit('refreshStudents');
          return this.refresh();
        });
      } else {
        return Promise.resolve();
      }
    });
  }

  async doDeleteItem(param: any) {
    return this.deleteTag({ tagId: param.studentTagId });
  }
}
